import * as React from 'react';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';



export class AbstractSingleMenu extends React.Component {

    constructor(props) {
        super();
        this.title = props.title;
        this.items = props.items;
        this.icon = props.icon;
        this.state = ({ open: false, main: null });
    }

    render() {
        return (
            <>
                <Button sx={{ textTransform: 'none' }} color="secondary" endIcon={<ArrowDropDownIcon />}
                    onClick={(e) => { this.setState({ open: !this.state.open, main: e.target }); }}>{this.title}</Button>
                <Menu open={this.state.open} anchorEl={this.state.main} elevation={0} onClose={() => { this.setState({ open: false }); }}>
                    {this.items.map((item, i) => {
                        if (!item.name) return (<Divider key={"item_" + i} />);
                        return (<MenuItem dense={true} key={"item_" + i} onClick={(e) => { this.setState({ open: false }); item.fun(); }} disableRipple>{this.icon ? (<ListItemIcon>{item.icon}</ListItemIcon>) : null}<ListItemText>{item.name}</ListItemText></MenuItem>);
                    })}
                </Menu>
            </>
        );
    }
}



export class AbstractMenu extends React.Component {

    constructor(menus, icon = true) {
        super();
        this.menus = menus;
        this.icon = icon;
    }

    filter(item) {
        return true;
    }

    render() {
        return (
            <React.Fragment>
                {this.menus.map((menu, i) => {
                    const items = [];
                    let divided = false;
                    menu.items.forEach((item) => {
                        if (!item.name) {
                            divided = true;
                            return;
                        }
                        if (!this.filter(item)) return;
                        if (items.length > 0 && divided) items.push({});
                        items.push(item);
                        divided = false;
                    });
                    return items.length > 0 ? (<AbstractSingleMenu key={"menu_" + i} title={menu.title} items={items} icon={this.icon} />) : null;
                })}
            </React.Fragment>
        );
    }
}



export default AbstractMenu;
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { TextInput, AbstractForm } from '../../main/AbstractForm';



class Password extends AbstractForm {
    constructor(props) {
        super('/feature/password.basic', 'put', { adminPass: '', adminPassNew: '', adminPassConfirm: '' });
        props.reference.password = this;
        this.state = { mode: 0 };
        this.err_ip = false;
    }

    validate(id, value) {
        if (id === 'adminPass') return value.length < 8 || value.length > 32 ? window.lan.password.err_adminPass : '';
        if (id === 'adminPassNew') return value.length < 8 || value.length > 32 ? window.lan.password.err_adminPassNew : '';
        if (id === 'adminPassConfirm') return value !== this.value.adminPassNew ? window.lan.password.err_adminPassConfirm : '';       
        return '';
    }

    result(result, info) {
        switch (result) {
            default:
                this.setState({ mode: 2 });
                return;
            case 1:
                this.err_ip = true;
                this.setState({ mode: 1 });
                break;
            case 2:
                this.validity.adminPass = window.lan.password.err_adminPass;
                this.setState({ mode: 1 });
                break;
        }
        this.forceUpdate();
    }

    draw() {
        return (
            <React.Fragment>
                <Dialog open={this.state.mode === 1} onClose={() => { this.setState({ mode: 0 }); this.reset(); }}>
                    <DialogTitle>{window.lan.password.title}</DialogTitle>
                    <DialogContent>
                        <Box component="form" noValidate autoComplete="off" sx={{ width: 500 }}><div align="left">
                            <TextInput form={this} id="adminPass" label={window.lan.password.password} type="password" fullwidth />
                            <Box sx={{ height: 20 }} />
                            <TextInput form={this} id="adminPassNew" label={window.lan.password.passwordNew} type="password" fullwidth />
                            <Box sx={{ height: 20 }} />
                            <TextInput form={this} id="adminPassConfirm" label={window.lan.password.passwordConfirm} type="password" fullwidth />
                            <Box sx={{ height: 30 }} />
                            {this.err_ip ? (<Alert variant="outlined" severity="error">{window.lan.password.err_ip}</Alert>) : (null)}</div>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { if (this.presubmit()) { this.setState({ mode: 0 }); this.submit(); } else this.forceUpdate(); }} autoFocus>{window.lan.password.submit}</Button>
                        <Button onClick={() => { this.setState({ mode: 0 }); this.reset(); }}>{window.lan.password.cancel}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.mode === 2} onClose={() => { this.setState({ mode: 0 }); this.reset(); }}>
                    <DialogTitle>{window.lan.password.title}</DialogTitle>
                    <DialogContent sx={{ width: 500 }}><DialogContentText>{window.lan.password.okay}</DialogContentText></DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ mode: 0 }); this.reset(); }} autoFocus>{window.lan.general.close}</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}



export default Password;
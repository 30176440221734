import * as React from 'react';

import { loading, refresh, get_json, post_json, put_json, delete_json, post_file } from './App';



class AbstractHandler extends React.Component {
    constructor(path, method) {
        super();
        this.path = path;
        this.method = method;
        this.timeout = 0;
    }

    handle(result, info) {
    }

    async submit(value = {}, path = '') {
        if (this.timeout > 0) clearTimeout(this.timeout);
        loading(true);

        if (path === '') path = this.path;
        this.path = path;

        if (this.method === 'delete') {
            const params = decodeURIComponent((new URLSearchParams(value)).toString());
            path = path + (path.indexOf('?') < 0 ? '?' : '&') + params;
        }

        let result = null;
        if (this.method === 'get') result = await get_json(path);
        if (this.method === 'post') result = await post_json(path, value);
        if (this.method === 'put') result = await put_json(path, value);
        if (this.method === 'delete') result = await delete_json(path);
        if (this.method === 'file') result = await post_file(path, value.file, value);
        if (!result.hasOwnProperty('result')) {
            this.handle(-10, {});
            loading(false);
            return;
        }

        if (result.result >= 0 && (result.siteSerialID !== window.config.site.serialID || result.adminSerialID !== window.config.admin.serialID)) {
            const tresult = await get_json('/buffer');
            if (tresult.hasOwnProperty('site'))
            {
                window.config.admin = tresult.admin;
                window.config.site = { ...window.config.site, ...tresult.site };
                window.config.buffer = tresult.buffer;
                refresh();
            }
        }
        this.timeout = setTimeout(loading.bind(null, false), 100);
        this.handle(result.result, result.info);
    }

    render() {
        return null;
    }
}



export default AbstractHandler;
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Chart, registerables } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { initialize_title, modify_title } from '../../main/App';
import { AbstractPage } from '../../main/AbstractPage';

import { getUTCDateStr } from '../../main/Utility';



Chart.register(...registerables);



class Information extends AbstractPage {
	constructor() {
		super('/feature/information.system');
		this.state = { info: null, total: 0, warning: 0, error: 0, size: 0, license: 0 };
	}

	result(result, info) {
		let total = 0;
		let warning = 0;
		let error = 0;
		let size = 0;
		let license = 0;
		info.site.forEach((row) => {
			size += row.size;
			license += row.license;
			total += 1;
			if (row.validTime < Math.floor(Date.now() / 1000) || row.license < row.size) error += 1;
			else if (row.validTime - Date.now() / 1000 < 30 * 86400 || 0.9 * row.license < row.size) warning += 1;
		});
		this.setState({ info: info, total: total, warning: warning, error: error, size: size, license: license });
	}

	color(value) {
		if (value < 0.8) return '#0070E0';
		if (value < 1) return '#C07000';
		return '#C03030';
	}

	chartSite() {
		return <React.Fragment><Typography><b>{window.lan.information.usage_site}</b><br /><br /></Typography><div style={{ width: 150 }}><Doughnut options={{ plugins: { legend: false } }} data={{
			labels:
				[window.lan.information.error_site, window.lan.information.warning_site, window.lan.information.normal_site],
			datasets: [{
				backgroundColor: [this.color(1), this.color(0.9), '#404040'],
				data: [this.state.error, this.state.warning, this.state.total - this.state.error - this.state.warning],
			}]
		}} /></div></React.Fragment>;
	}

	chartSize() {
		return <React.Fragment><Typography><b>{window.lan.information.usage_size}</b><br /><br /></Typography><div style={{ width: 150 }}><Doughnut options={{ plugins: { legend: false } }} data={{
			labels:
				[window.lan.information.used_size, window.lan.information.free_size],
			datasets: [{
				backgroundColor: [this.color(this.state.size / (this.state.info.size > 0 ? this.state.info.size : 1)), '#A0A0A0'],
				data: [this.state.size, Math.max(0, this.state.info.size - this.state.size)],
			}]
		}} /></div></React.Fragment>;
	}

	chartLicense() {
		return <React.Fragment><Typography><b>{window.lan.information.usage_license}</b><br /><br /></Typography><div style={{ width: 150 }}><Doughnut options={{ plugins: { legend: false } }} data={{
			labels:
				[window.lan.information.used_license, window.lan.information.free_license],
			datasets: [{
				backgroundColor: [this.color(this.state.license / (this.state.info.license > 0 ? this.state.info.license : 1)), '#A0A0A0'],
				data: [this.state.license, Math.max(0, this.state.info.license - this.state.license)],
			}]
		}} /></div></React.Fragment>;
	}

	tdraw() {
		const size = this.state.size / (this.state.info.size > 0 ? this.state.info.size : 1);
		const license = this.state.license / (this.state.info.license > 0 ? this.state.info.license : 1);
		return (<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
			<Grid item xs={6}>
				<Typography><b>{window.config.site.name}</b><br /><br /></Typography>
				<TableContainer component={Grid}>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell>{window.lan.information.version}</TableCell>
								<TableCell>{window.lan.information.backClient}</TableCell>
								<TableCell>{window.lan.information.backServer}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell>{this.state.info.version}</TableCell>
								<TableCell>{this.state.info.backClient}</TableCell>
								<TableCell>{this.state.info.backServer}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			<Grid item xs={6} style={{marginTop: "-100px"}}>
				<Grid container direction="row" justifyContent="center" alignItems="center" spacing={3} style={{height: "350px"}}>
					<Grid item>
						{this.chartSite()}<Typography sx={{ fontSize: "23px", marginTop: "-95px" }} color={this.color(this.state.error + (this.state.warning > 0 ? 0.9 : 0))}>{this.state.total}</Typography>
					</Grid>
					<Grid item>
						{this.chartSize()}<Typography sx={{ fontSize: "23px", marginTop: "-95px" }} color={this.color(size)}>{Math.floor(size * 100)}%</Typography>
					</Grid>
					<Grid item>
						{this.chartLicense()}<Typography sx={{ fontSize: "23px", marginTop: "-95px" }} color={this.color(license)}>{Math.floor(license * 100)}%</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<TableContainer component={Grid}>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell style={{backgroundColor: "#C0C0C0"}}><b>{window.lan.information.site_position}</b></TableCell>
								<TableCell style={{backgroundColor: "#C0C0C0"}}><b>{window.lan.information.site_name}</b></TableCell>
								<TableCell style={{backgroundColor: "#C0C0C0"}}><b>{window.lan.information.site_size}</b></TableCell>
								<TableCell style={{backgroundColor: "#C0C0C0"}}><b>{window.lan.information.site_license}</b></TableCell>
								<TableCell style={{backgroundColor: "#C0C0C0"}}><b>{window.lan.information.site_validTime}</b></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.info.site.map((item) => {
								let style = {};
								if (item.validTime < Math.floor(Date.now() / 1000) || item.license < item.size) style = {backgroundColor: "#FFA0A0"};
								else if (item.validTime - Date.now() / 1000 < 30 * 86400 || 0.9 * item.license < item.size) style = {backgroundColor: "#FFD0A0"};
								return (<TableRow key={item.position}>
									<TableCell style={style}>{item.position}</TableCell>
									<TableCell style={style}>{item.name}</TableCell>
									<TableCell style={style}>{item.size}</TableCell>
									<TableCell style={style}>{item.license}</TableCell>
									<TableCell style={style}>{getUTCDateStr(item.validTime)}</TableCell>
								</TableRow>);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Grid>);
	}

	draw() {
		initialize_title();
		modify_title(2, window.lan.information.title);
		return this.pdraw([{ info: window.lan.information.title }], null, null, this.tdraw());
	}
}



export default Information;
import * as React from 'react';

import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';

import { initialize_title, modify_title, load } from '../../main/App';
import AbstractTable from '../../main/AbstractTable';
import { TextInput, AbstractDialog } from '../../main/AbstractForm';
import { AbstractMenu } from '../../main/AbstractMenu';
import { getTimeStr } from '../../main/Utility';



class Add extends AbstractDialog {
    constructor(props) {
        super('/feature/static.basic', 'post', { 'index': '', 'name': '' }, props.reference, 'add_diag', window.lan.static.add, window.lan.static.add_submit);
    }

    options() {
        return [
            (<TextInput form={this} id="index" label={window.lan.static.add_index} fullwidth />),
            (<TextInput form={this} id="name" label={window.lan.static.add_name} fullwidth />)
        ];
    }

    validate(id, value) {
        if (id === 'index') {
            let isOK = true;
            window.config.buffer['static/static'].forEach((item) => { if (item.index.toLowerCase() === value.toLowerCase()) isOK = false; });
            return value.length < 1 || value.length > 31 || !isOK ? window.lan.static.err[0] : '';
        }
        if (id === 'name') return value.length < 1 || value.length > 255 ? window.lan.static.err[1] : '';
        return '';
    }

    result(result, info) {
        if (result === 9) {
            this.validity['index'] = window.lan.static.err[0];
            this.openmain();
            return;
        }
        if (result === 0)
            this.reference.dm.add(info.id);
        this.reference.refresh();
    }
}



class Edit extends AbstractDialog {
    constructor(props) {
        super('/feature/static.basic', 'put', {}, props.reference, 'edit_diag', window.lan.static.edit, window.lan.static.edit_submit);
    }

    options() {
        return [
            (<TextInput form={this} id="name" label={window.lan.static.edit_name} fullwidth />),
            (<TextInput form={this} id="parentIndex" label={window.lan.static.edit_parent_index} fullwidth />)
        ];
    }

    validate(id, value) {
        if (id === 'name') return value.length < 1 || value.length > 255 ? window.lan.static.err[1] : '';
        if (id === 'parentIndex') {
            if (value.length > 31) return window.lan.static.err[2];
            const arr = [this.value.index];
            let parentIndex = value;
            while (parentIndex !== '') {
                if (arr.includes(parentIndex)) return window.lan.static.err[2];
                let isOk = false;
                for (const info of window.config.buffer['static/static']) {
                    if (info.index === parentIndex) {
                        isOk = true;
                        arr.push(parentIndex);
                        parentIndex = info.parentIndex;
                        break;
                    }
                }
                if (!isOk) return window.lan.static.err[2];
            }
        }
        return '';
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.update(info.id);
        this.reference.refresh();
    }
}



class Del extends AbstractDialog {
    constructor(props) {
        super('/feature/static.basic', 'delete', {}, props.reference, 'del_diag', window.lan.static.del, window.lan.general.submit);
    }

    options() {
        return [window.lan.static.del_tip];
    }

    check(IDs) {
        const arr = [];
        for (const info of window.config.buffer['static/static']) {
            if (IDs.includes(info.id)) arr.push(info.index);
        }
        for (const info of window.config.buffer['static/static']) {
            if (info.parentIndex !== '' && !IDs.includes(info.id) && arr.includes(info.parentIndex)) {
                this.setState({ mode: 2, information: window.lan.static.err[3] });
                return false;
            }
        }
        return true;
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.remove(info.list);
        this.reference.refresh();
    }
}



class Copy extends AbstractDialog {
    constructor(props) {
        super('/feature/static.basic/copy', 'post', { 'index': '' }, props.reference, 'copy_diag', window.lan.static.copy, window.lan.static.copy_submit);
    }

    options() {
        return [
            (<TextInput form={this} id="index" label={window.lan.static.copy_index} fullwidth />)
        ];
    }

    validate(id, value) {
        if (id === 'index') {
            let isOK = true;
            window.config.buffer['static/static'].forEach((item) => { if (item.index.toLowerCase() === value.toLowerCase()) isOK = false; });
            return value.length < 1 || value.length > 31 || !isOK ? window.lan.static.err[0] : '';
        }
        return '';
    }

    result(result, info) {
        if (result === 9) {
            this.validity['index'] = window.lan.static.err[0];
            this.openmain();
            return;
        }
        if (result === 0)
            this.reference.dm.add(info.id);
        this.reference.refresh();
    }
}



class Menu extends AbstractMenu {

    constructor(props) {
        const items = [];
        items.push({ name: window.lan.static.edit_page, icon: (<VerticalSplitIcon fontSize="small" />), fun: () => { load('/page/' + props.row.ID); } });
        items.push({});
        items.push({ name: window.lan.static.copy, icon: (<ContentCopyIcon fontSize="small" />), fun: () => { props.reference.copy_diag.value = { 'staticID': props.row.ID }; props.reference.copy_diag.openmain(); } });
        items.push({ name: window.lan.static.edit, icon: (<EditIcon fontSize="small" />), fun: () => { props.reference.edit_diag.value = {...props.row}; props.reference.edit_diag.openmain(); } });
        items.push({ name: window.lan.static.del, icon: (<ClearIcon fontSize="small" />), fun: () => { if (!props.reference.del_diag.check([props.row.ID])) return; props.reference.del_diag.value = { "IDList": props.row.ID }; props.reference.del_diag.openmain(); } });
        super([{ title: window.lan.general.operation, items: items }]);
    }
}



class Static extends AbstractTable {

    constructor() {
        super('/feature/static.basic',
            [
                { sortindex: 0, label: window.lan.static.id },
                { sortindex: 1, label: window.lan.static.index },
                { sortindex: 2, label: window.lan.static.parent_index },
                { sortindex: 3, label: window.lan.static.name, style: { width: '45%' } },
                { sortindex: 4, label: window.lan.static.update_time }
            ],
            window.lan.static.infobox, 'Static', '', '0$0', [],
            [
                window.lan.static.id,
                window.lan.static.index,
                window.lan.static.parent_index,
                window.lan.static.name,
                window.lan.static.update_time
            ], true, false);
    }

    draw() {
        initialize_title();
        modify_title(2, window.lan.static.title);
        return this.pdraw([{ info: window.lan.static.title }], (<React.Fragment><Add reference={this} /><Edit reference={this} /><Del reference={this} /><Copy reference={this} /></React.Fragment>), this.tdraw());
    }

    drawMenu(row, rowindex) {
        return (<Menu key={Date.now()} reference={this} row={row} rowindex={rowindex} />);
    }

    drawCell(row, rowindex, cellindex) {
        if (cellindex === 1) return row.ID;
        if (cellindex === 2) return row.index;
        if (cellindex === 3) return row.parentIndex;
        if (cellindex === 4) return row.name;
        if (cellindex === 5) return getTimeStr(row.updateTime);
    }

    drawEmptyCell() {
        return window.lan.static.empty;
    }

    drawToolbarLeft() {
        return (<Button variant="contained" disableElevation onClick={() => { if (this.state.selected.length === 0 || !this.del_diag.check(this.state.selected)) return; this.del_diag.value = { "IDList": this.state.selected.join(',') }; this.del_diag.openmain(); }}>{window.lan.static.del}</Button>);
    }

    drawToolbarRight() {
        return (<Button variant="outlined" disableElevation onClick={() => { this.add_diag.openmain(); }}>{window.lan.static.add}</Button>);
    }
}



if (!window.logfun) window.logfun = {};

window.logfun.add_static = function (info) {
    return window.lan.loginfo.add_static.replace('%A%', info.id).replace('%B%', info.index).replace('%C%', info.name);
}

window.logfun.remove_statics = function (info) {
    return window.lan.loginfo.remove_statics.replace('%A%', info.ids.join(', '));
}

window.logfun.edit_static = function (info) {
    return window.lan.loginfo.edit_static.replace('%A%', info.id).replace('%B%', info.name);
}

window.logfun.copy_static = function (info) {
    return window.lan.loginfo.copy_static.replace('%A%', info.static_id).replace('%B%', info.id).replace('%C%', info.index);
}



export default Static;
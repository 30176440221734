import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

import { refresh, initialize_title, modify_title } from '../../main/App';
import { TextInput, CheckboxInput, AbstractForm } from '../../main/AbstractForm';



class Login extends AbstractForm {
    constructor() {
        super('/feature/login.basic', 'put', { adminName: '', adminPass: '' }, window.lan.login.infobox);
        this.err_ip = false;
    }

    validate(id, value) {
        if (id === 'adminName') return value.length < 1 || value.length > 31 ? window.lan.login.err_adminName : '';
        if (id === 'adminPass') return value.length < 8 || value.length > 32 ? window.lan.login.err_adminPass : '';
        return '';
    }

    result(result, info) {
        switch (result) {
            default:
                refresh();
                return;
            case 1:
                this.err_ip = true;
                break;
            case 2:
                this.validity.adminPass = window.lan.login.err_adminPass;
                break;
        }
        this.forceUpdate();
    }

    draw() {
        initialize_title();
        modify_title(2, window.lan.login.title);

        return (
            <div align="center" style={{height: "70vh"}}>
                <br /><br /><br /><br />
                <Box component="form" noValidate autoComplete="off" sx={{ width: 500 }}><div align="left">
                    <TextInput form={this} id="adminName" label={window.lan.login.username} fullwidth />
                    <Box sx={{ height: 20 }} />
                    <TextInput form={this} id="adminPass" label={window.lan.login.password} type="password" fullwidth />
                    <Box sx={{ height: 20 }} />
                    <CheckboxInput form={this} id="saveKey" label={window.lan.login.save_key} value="1" />
                    <Box sx={{ height: 30 }} />
                    <Button variant="contained" onClick={() => { if (this.presubmit()) this.submit(); else this.forceUpdate(); }}>{window.lan.login.submit}</Button>
                    <Box sx={{ height: 30 }} />
                    {this.err_ip ? (<Alert variant="outlined" severity="error">{window.lan.login.err_ip}</Alert>) : (null)}</div>
                </Box>
            </div>
        );
    }
}



export default Login;
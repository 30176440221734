import * as React from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import InventoryIcon from '@mui/icons-material/Inventory';

import { initialize_title, load, modify_title } from '../../main/App';
import AbstractTable from '../../main/AbstractTable';
import { TextInput, SelectInput, AbstractDialog } from '../../main/AbstractForm';
import { AbstractMenu } from '../../main/AbstractMenu';



class Add extends AbstractDialog {
    constructor(props) {
        super('/feature/service.basic', 'post', { 'name': '', 'index': '' }, props.reference, 'add_diag', window.lan.service.add, window.lan.service.add_submit);
    }

    options() {
        const options = [];
        const arr = window.config.buffer ? window.config.buffer['service/service'].map((item) => item.index) : [];
        for (let i = 8; i < 63; i++) if (!arr.includes(i)) options.push({ name: i, value: i });
        return [
            (<Stack direction="row" spacing={5}>
                <TextInput form={this} id="name" label={window.lan.service.add_name} fullwidth />
                <SelectInput form={this} id="index" label={window.lan.service.add_index} options={options} fullwidth />
            </Stack>)
        ];
    }

    validate(id, value) {
        if (id === 'name') return value.length < 1 || value.length > 31 ? window.lan.service.err[0] : '';
        if (id === 'index') return isNaN(parseInt(value)) || parseInt(value) < 8 || parseInt(value) > 62 ? window.lan.service.err[2] : '';
        return '';
    }

    result(result, info) {
        if (result === 9) {
            this.openresult(window.lan.service.err[2]);
            return;
        }
        if (result === 0)
            this.reference.dm.add(info.id);
        this.reference.refresh();
    }
}



class Edit extends AbstractDialog {
    constructor(props) {
        super('/feature/service.basic', 'put', {}, props.reference, 'edit_diag', window.lan.service.edit, window.lan.service.edit_submit);
    }

    options() {
        return [
            (<TextInput form={this} id="name" label={window.lan.service.edit_name} fullwidth />),
        ];
    }

    validate(id, value) {
        if (id === 'name') return value.length < 1 || value.length > 31 ? window.lan.service.err[0] : '';
        return '';
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.update(info.id);
        this.reference.refresh();
    }
}



class Del extends AbstractDialog {
    constructor(props) {
        super('/feature/service.basic', 'delete', {}, props.reference, 'del_diag', window.lan.service.del, window.lan.general.submit);
    }

    options() {
        return [window.lan.service.del_tip];
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.remove(info.list);
        this.reference.refresh();
    }
}



class Menu extends AbstractMenu {

    constructor(props) {
        const items = [];
        if (window.config.admin.right[3] & 2) items.push({ name: window.lan.service.article_tip, icon: (<InventoryIcon fontSize="small" />), fun: () => { load('/article/9$' + props.row.index); } });
        if (props.row.index >= 8) {
            items.push({});
            items.push({ name: window.lan.service.edit, icon: (<EditIcon fontSize="small" />), fun: () => { props.reference.edit_diag.value = {...props.row}; props.reference.edit_diag.openmain(); } });
            items.push({ name: window.lan.service.del, icon: (<ClearIcon fontSize="small" />), fun: () => { props.reference.del_diag.value = { "IDList": props.row.ID }; props.reference.del_diag.openmain(); } });
        }
        super([{ title: window.lan.general.operation, items: items }]);
    }
}



class Service extends AbstractTable {

    constructor() {
        super('/feature/service.basic',
            [
                { sortindex: 0, label: window.lan.service.id },
                { sortindex: 1, label: window.lan.service.index },
                { sortindex: 2, label: window.lan.service.name, style: { width: '60%' } }
            ],
            window.lan.service.infobox, 'Service', '', '0$0', [],
            [
                window.lan.service.id,
                window.lan.service.index,
                window.lan.service.name
            ], true);
    }

    draw() {
        initialize_title();
        modify_title(2, window.lan.service.title);
        return this.pdraw([{ info: window.lan.service.title }], (<React.Fragment><Add reference={this} /><Edit reference={this} /><Del reference={this} /></React.Fragment>), this.tdraw());
    }

    drawMenu(row, rowindex) {
        return (<Menu key={Date.now()} reference={this} row={row} rowindex={rowindex} />);
    }

    drawCell(row, rowindex, cellindex) {
        if (cellindex === 1) return row.ID;
        if (cellindex === 2) return row.index;
        if (cellindex === 3) return (<Stack direction="row" spacing={2}><Typography>{row.index < 8 ? (window.servicename[row.index] ? window.servicename[row.index] : '') : row.name}</Typography>
            {row.index < 8 ? <Chip label={window.lan.service.system} color="primary" size="small" /> : null}</Stack>);
    }

    drawEmptyCell() {
        return window.lan.service.empty;
    }

    drawToolbarLeft() {
        return (<Button variant="contained" disableElevation onClick={() => { if (!this.checkIDList()) { this.del_diag.openresult(window.lan.service.err[1]); return; } if (this.state.selected.length === 0) return; this.del_diag.value = { "IDList": this.state.selected.join(',') }; this.del_diag.openmain(); }}>{window.lan.service.del}</Button>);
    }

    drawToolbarRight() {
        return (<Button variant="outlined" disableElevation onClick={() => { this.add_diag.openmain(); }}>{window.lan.service.add}</Button>);
    }

    checkIDList() {
        const arr = window.config.buffer.service.map((item) => this.state.selected.includes(item.id) ? item.index : -1);
        for (let i = 0; i < 8; i++) if (arr.includes(i)) return false;
        return true;
    }
}



if (!window.logfun) window.logfun = {};

window.logfun.add_service = function (info) {
    return window.lan.loginfo.add_service.replace('%A%', info.id).replace('%B%', info.index).replace('%B%', info.name);
}

window.logfun.remove_services = function (info) {
    return window.lan.loginfo.remove_services.replace('%A%', info.ids.join(', '));
}

window.logfun.edit_service = function (info) {
    return window.lan.loginfo.edit_service.replace('%A%', info.id).replace('%B%', info.name);
}



export default Service;
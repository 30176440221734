import * as React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { initialize_title, modify_title, get_url } from '../../main/App';
import AbstractTable from '../../main/AbstractTable';
import { TooltipWrapper, TextInput, SelectInput, DateInput, AbstractDialog } from '../../main/AbstractForm';
import { AbstractMenu } from '../../main/AbstractMenu';
import { getTimeStr, getUTCDateStr, checkEmail, checkJSON } from '../../main/Utility';

import dayjs from 'dayjs';



class Add extends AbstractDialog {
    constructor(props) {
        super('/feature/site.basic', 'post', { name: '', language: window.language }, props.reference, 'add_diag', window.lan.site.add, window.lan.site.add_submit);
    }

    options() {
        const loptions = Object.entries(window.lan.language).map(([key, name]) => ({ name: name, value: key }));
        return [
            (<Stack direction="row" spacing={5}>
                <TextInput form={this} id="name" label={window.lan.site.add_name} fullwidth />
                <SelectInput form={this} id="language" label={window.lan.site.add_language} options={loptions} fullwidth />
            </Stack>)
        ];
    }

    validate(id, value) {
        if (id === 'name') return value.length < 1 || value.length > 31 ? window.lan.site.err[0] : '';
        return '';
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.add(info.id);
        this.reference.refresh();
    }
}



class Edit extends AbstractDialog {
    constructor(props) {
        super('/feature/site.basic', 'put', {}, props.reference, 'edit_diag', window.lan.site.edit, window.lan.site.edit_submit, 70);
    }

    openmain() {
        this.value._validTime = dayjs.unix(this.value.validTime);
        super.openmain();
    }

    options() {
        return [
            (<Stack direction="row" spacing={5}>
                <TextInput form={this} id="name" label={window.lan.site.edit_name} fullwidth />
                <TextInput form={this} id="frontClient" label={window.lan.site.edit_front_client} fullwidth />
                <TextInput form={this} id="frontServer" label={window.lan.site.edit_front_server} fullwidth />
            </Stack>),
            (<Stack direction="row" spacing={5}>
                <TooltipWrapper input={<TextInput form={this} id="email" label={window.lan.site.edit_email} fullwidth />} tooltip={window.lan.site.edit_email_tip} />
                <TextInput form={this} id="backClient" label={window.lan.site.edit_back_client} fullwidth />
                <TextInput form={this} id="backServer" label={window.lan.site.edit_back_server} fullwidth />
            </Stack>),
            (<Stack direction="row" spacing={5}>
                <SelectInput form={this} id="language" label={window.lan.site.edit_language} options={
                     Object.entries(window.lan.language).map(([key, name]) => ({ name: name, value: key }))
                } fullwidth />
                <TooltipWrapper input={<TextInput form={this} id="license" label={window.lan.site.edit_license} fullwidth />} tooltip={window.lan.site.edit_license_tip} />
                <TooltipWrapper input={<DateInput form={this} id="_validTime" label={window.lan.site.edit_valid_time} fullwidth />} tooltip={window.lan.site.edit_valid_time_tip} />
            </Stack>),
            (<TextInput form={this} id="featureList" label={window.lan.site.edit_feature} fullwidth multiline rows={4} />),
            (<TextInput form={this} id="pluginList" label={window.lan.site.edit_plugin} fullwidth multiline rows={4} />),
            (<TextInput form={this} id="configuration" label={window.lan.site.edit_configuration} fullwidth multiline rows={4} />),
            (<TextInput form={this} id="remark" label={window.lan.site.edit_remark} fullwidth multiline rows={4} />)
        ];
    }

    validate(id, value) {
        if (id === 'name') return value.length < 1 || value.length > 31 ? window.lan.site.err[0] : '';
        if (id === 'frontClient') return value.length > 127 ? window.lan.site.err[2] : '';
        if (id === 'frontServer') return value.length > 127 ? window.lan.site.err[3] : '';
        if (id === 'backClient') return value.length > 127 ? window.lan.site.err[4] : '';
        if (id === 'backServer') return value.length > 127 ? window.lan.site.err[5] : '';
        if (id === 'email') return value.length > 0 && !checkEmail(value) ? window.lan.site.err[6] : '';
        if (id === 'license') return isNaN(parseInt(value)) || parseInt(value) < 0 ? window.lan.site.err[7] : '';
        if (id === 'featureList') return value.length > 4095 || !checkJSON(value) ? window.lan.site.err[8] : '';
        if (id === 'pluginList') return value.length > 4095 || !checkJSON(value) ? window.lan.site.err[9] : '';
        if (id === 'configuration') return value.length > 4095 || !checkJSON(value) ? window.lan.site.err[10] : '';
        if (id === 'remark') return value.length > 4095 ? window.lan.site.err[11] : '';
        return '';
    }

    presubmit() {
        if (!super.presubmit()) return false;
        this.value.validTime = this.value._validTime.unix();
        for (const key in this.value) if (key.charAt(0) === '_') delete this.value[key];
        return true;
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.update(info.id);
        this.reference.refresh();
    }
}



class Del extends AbstractDialog {
    constructor(props) {
        super('/feature/site.basic', 'delete', {}, props.reference, 'del_diag', window.lan.site.del, window.lan.general.submit);
    }

    options() {
        return [window.lan.site.del_tip];
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.remove(info.list);
        this.reference.refresh();
    }
}



class Copy extends AbstractDialog {
    constructor(props) {
        super('/feature/site.basic/copy', 'post', { name: '' }, props.reference, 'copy_diag', window.lan.site.copy, window.lan.site.copy_submit);
    }

    options() {
        return [
            (<TextInput form={this} id="name" label={window.lan.site.copy_name} fullwidth />)
        ];
    }

    validate(id, value) {
        if (id === 'name') return value.length === 0 || value.length > 31 ? window.lan.site.err[0] : '';
        return '';
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.add(info.id);
        this.reference.refresh();
    }
}



class Menu extends AbstractMenu {

    constructor(props) {
        const items = [];
        if (props.row.ID > 1) items.push({ name: window.lan.site.manage, icon: (<ManageAccountsIcon fontSize="small" />), fun: () => { window.open(get_url('/feature/site.basic/manage?ID=' + props.row.ID), '_blank').focus(); } });
        items.push({});
        items.push({ name: window.lan.site.edit, icon: (<EditIcon fontSize="small" />), fun: () => { props.reference.edit_diag.value = {...props.row}; props.reference.edit_diag.openmain(); } });
        if (props.row.ID > 1) items.push({ name: window.lan.site.copy, icon: (<ContentCopyIcon fontSize="small" />), fun: () => { props.reference.copy_diag.value = { 'siteID': props.row.ID, 'name': props.row.name }; props.reference.copy_diag.openmain(); } });
        if (props.row.ID > 1) items.push({ name: window.lan.site.del, icon: (<ClearIcon fontSize="small" />), fun: () => { props.reference.del_diag.value = { "IDList": props.row.ID }; props.reference.del_diag.openmain(); } });
        super([{ title: window.lan.general.operation, items: items }]);
    }
}



class Site extends AbstractTable {

    constructor() {
        super('/feature/site.basic',
            [
                { sortindex: 0, label: window.lan.site.id },
                { sortindex: 5, label: window.lan.site.name },
                { sortindex: 1, label: window.lan.site.front_client },
                { sortindex: 3, label: window.lan.site.back_client },
                { sortindex: 6, label: window.lan.site.email },
                { sortindex: 8, label: window.lan.site.insert_time },
                { sortindex: 9, label: window.lan.site.update_time }
            ],
            window.lan.site.infobox, 'Site', '', '0$0', [],
            [
                window.lan.site.id,
                window.lan.site.front_client,
                window.lan.site.front_server,
                window.lan.site.back_client,
                window.lan.site.back_server,
                window.lan.site.name,
                window.lan.site.email,
                window.lan.site.language,
                window.lan.site.insert_time,
                window.lan.site.update_time,
                window.lan.site.size,
                window.lan.site.license,
                window.lan.site.valid_time
            ], true, true);
    }

    draw() {
        initialize_title();
        modify_title(2, window.lan.site.title);
        return this.pdraw([{ info: window.lan.site.title }], (<React.Fragment><Add reference={this} /><Edit reference={this} /><Copy reference={this} /><Del reference={this} /></React.Fragment>), this.tdraw());
    }

    drawMenu(row, rowindex) {
        return (<Menu key={Date.now()} reference={this} row={row} rowindex={rowindex} />);
    }

    drawDetail(row, rowindex) {
        return (<Grid container justifyContent="flex-start" sx={{ padding: '5px' }}>
            <Grid item xs={3}><Typography align="left"><b>{row.ID === 1 ? window.lan.site.system_size : window.lan.site.size}:</b><br />{row.size}</Typography></Grid>
            <Grid item xs={3}><Typography align="left"><b>{row.ID === 1 ? window.lan.site.system_license : window.lan.site.license}:</b><br />{row.license}</Typography></Grid>
            {row.ID > 1 ? <Grid item xs={3}><Typography align="left"><b>{window.lan.site.valid_time}:</b><br />{getUTCDateStr(row.validTime)}</Typography></Grid> : <Grid item xs={3}></Grid>}
            <Grid item xs={3}><Typography align="left"><b>{window.lan.site.language}:</b><br />{window.lan.language[row.language]}</Typography></Grid>
            <Grid item xs={12}><Typography align="left"><b>{window.lan.site.feature_list}:</b><br />{JSON.stringify(JSON.parse(row.featureList), null, "\t")}</Typography></Grid>
            {row.ID > 1 ? <Grid item xs={12}><Typography align="left"><b>{window.lan.site.plugin_list}:</b><br />{JSON.stringify(JSON.parse(row.pluginList), null, "\t")}</Typography></Grid> : null}
            {row.ID > 1 ? <Grid item xs={12}><Typography align="left"><b>{window.lan.site.configuration}:</b><br />{JSON.stringify(JSON.parse(row.configuration), null, "\t")}</Typography></Grid> : null}
            <Grid item xs={12}><Typography align="left"><b>{window.lan.site.remark}:</b><br />{row.remark !== '' ? row.remark : window.lan.general.none}</Typography></Grid>
        </Grid>);
    }

    drawCell(row, rowindex, cellindex) {
        if (cellindex === 1) return row.ID;
        if (cellindex === 2) return (<Stack direction="row" spacing={2}><Typography>{row.name}</Typography>
            {row.ID === 1 ? <Chip label={window.lan.site.system} color="primary" size="small" /> : null}
            {row.ID > 1 && (row.validTime < Math.floor(Date.now() / 1000) || row.license < row.size) ? <Chip label={window.lan.site.unlicensed_tip} color="error" size="small" /> : null}
            {row.ID > 1 && !(row.validTime < Math.floor(Date.now() / 1000) || row.license < row.size) && (row.validTime - Date.now() / 1000 < 30 * 86400 || 0.9 * row.license < row.size) ? <Chip label={window.lan.site.license_tip} color="warning" size="small" /> : null}</Stack>);
        if (cellindex === 3) return (<Stack spacing={0}><Link href={row.frontClient} underline="none" target="_blank">{row.frontClient}</Link><Typography variant="caption">{row.frontServer}</Typography></Stack>);
        if (cellindex === 4) return (<Stack spacing={0}><Link href={row.backClient} underline="none" target="_blank">{row.backClient}</Link><Typography variant="caption">{row.backServer}</Typography></Stack>);
        if (cellindex === 5) return <Link href={"mailto:" + row.email} underline="none">{row.email}</Link>;
        if (cellindex === 6) return getTimeStr(row.insertTime);
        if (cellindex === 7) return getTimeStr(row.updateTime);
    }

    drawToolbarLeft() {
        return (<React.Fragment>
            <Button variant="contained" disableElevation onClick={() => { if (this.state.selected.includes(1)) { this.del_diag.openresult(window.lan.site.err[1]); return; } if (this.state.selected.length === 0) return; this.del_diag.value = { "IDList": this.state.selected.join(',') }; this.del_diag.openmain(); }}>{window.lan.site.del}</Button>
        </React.Fragment>);
    }

    drawToolbarRight() {
        return (<Button variant="outlined" disableElevation onClick={() => { this.add_diag.openmain(); }}>{window.lan.site.add}</Button>);
    }
}



if (!window.logfun) window.logfun = {};

window.logfun.add_site = function (info) {
    return window.lan.loginfo.add_site.replace('%A%', info.id).replace('%B%', info.name);
}

window.logfun.remove_sites = function (info) {
    return window.lan.loginfo.remove_sites.replace('%A%', info.ids.join(', '));
}

window.logfun.edit_site = function (info) {
    return window.lan.loginfo.edit_site.replace('%A%', info.id).replace('%B%', info.name).replace('%C%', info.license).replace('%D%', getUTCDateStr(info.validTime));
}

window.logfun.copy_site = function (info) {
    return window.lan.loginfo.copy_site.replace('%A%', info.site_id).replace('%B%', info.id).replace('%C%', info.name);
}

window.logfun.manage_site = function (info) {
    return window.lan.loginfo.manage_site.replace('%A%', info.id);
}



export default Site;
import * as React from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { initialize_title, modify_title } from '../../main/App';
import AbstractTable from '../../main/AbstractTable';
import { TooltipWrapper, TextInput, SelectInput, AbstractDialog } from '../../main/AbstractForm';
import { AbstractMenu } from '../../main/AbstractMenu';



class Add extends AbstractDialog {
    constructor(props) {
        super('/feature/profile.basic', 'post', { 'name': '' }, props.reference, 'add_diag', window.lan.profile.add, window.lan.profile.add_submit);
    }

    options() {
        return [
            (<TextInput form={this} id="name" label={window.lan.profile.add_name} fullwidth />)
        ];
    }

    validate(id, value) {
        if (id === 'name') return value.length < 1 || value.length > 31 ? window.lan.profile.err[0] : '';
        return '';
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.add(info.id);
        this.reference.refresh();
    }
}



class Edit extends AbstractDialog {
    constructor(props) {
        super('/feature/profile.basic', 'put', {}, props.reference, 'edit_diag', window.lan.profile.edit, window.lan.profile.edit_submit, 60);
    }

    options() {
        const options = [];
        Object.entries(window.profiletype).forEach(([key, name], i) => {
            options.push({ name: name, value: key });
        });

        return [
            (<Stack direction="row" spacing={5}>
                <SelectInput form={this} id="type" label={window.lan.profile.edit_type} options={options} fullwidth />
                <TextInput form={this} id="name" label={window.lan.profile.edit_name} fullwidth />
                <Stack direction="row" spacing={0} width='100%'>
                    <TextInput form={this} id="topMargin" label={window.lan.profile.edit_top_margin} fullwidth />
                    <TextInput form={this} id="bottomMargin" label={window.lan.profile.edit_bottom_margin} fullwidth />
                    <TextInput form={this} id="leftMargin" label={window.lan.profile.edit_left_margin} fullwidth />
                    <TextInput form={this} id="rightMargin" label={window.lan.profile.edit_right_margin} fullwidth />
                </Stack>
            </Stack>),
            (<TextInput form={this} id="title" label={window.lan.profile.edit_title} fullwidth />),
            (<TooltipWrapper input={<TextInput form={this} id="html" label={window.lan.profile.edit_html} fullwidth multiline rows={8} />} tooltip={window.lan.profile.edit_html_tip} />)
        ];
    }

    validate(id, value) {
        if (id === 'name') return value.length < 1 || value.length > 31 ? window.lan.profile.err[0] : '';
        if (id === 'topMargin' || id === 'bottomMargin' || id === 'leftMargin' || id === 'rightMargin') return isNaN(value) || Math.abs(value) > 2147483647 ? window.lan.profile.err[1] : '';
        if (id === 'title') return value.length > 255 ? window.lan.profile.err[2] : '';
        if (id === 'html') return value.length > 65535 ? window.lan.profile.err[3] : '';
        return '';
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.update(info.id);
        this.reference.refresh();
    }
}



class Del extends AbstractDialog {
    constructor(props) {
        super('/feature/profile.basic', 'delete', {}, props.reference, 'del_diag', window.lan.profile.del, window.lan.general.submit);
    }

    options() {
        return [window.lan.profile.del_tip];
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.remove(info.list);
        this.reference.refresh();
    }
}



class Copy extends AbstractDialog {
    constructor(props) {
        super('/feature/profile.basic/copy', 'post', {}, props.reference, 'copy_diag');
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.add(info.id);
        this.reference.refresh();
    }
}



class Menu extends AbstractMenu {
    constructor(props) {
        const items = [];
        items.push({ name: window.lan.profile.copy, icon: (<ContentCopyIcon fontSize="small" />), fun: () => { props.reference.copy_diag.value = { 'profileID': props.row.ID }; props.reference.copy_diag.submit(); } });
        items.push({ name: window.lan.profile.edit, icon: (<EditIcon fontSize="small" />), fun: () => { props.reference.edit_diag.value = { ...props.row }; props.reference.edit_diag.openmain(); } });
        items.push({ name: window.lan.profile.del, icon: (<ClearIcon fontSize="small" />), fun: () => { props.reference.del_diag.value = { "IDList": props.row.ID }; props.reference.del_diag.openmain(); } });
        super([{ title: window.lan.general.operation, items: items }]);
    }
}



class Profile extends AbstractTable {

    constructor() {
        super('/feature/profile.basic',
            [
                { sortindex: 0, label: window.lan.profile.id },
                { sortindex: 2, label: window.lan.profile.type },
                { sortindex: 1, label: window.lan.profile.name, style: { width: '70%' } }
            ],
            window.lan.profile.infobox, 'Profile', '', '0$0', [],
            [
                window.lan.profile.id,
                window.lan.profile.name,
                window.lan.profile.type,
                window.lan.profile.tit
            ], true);
    }

    draw() {
        initialize_title();
        modify_title(2, window.lan.profile.title);
        return this.pdraw([{ info: window.lan.profile.title }], (<React.Fragment><Add reference={this} /><Edit reference={this} /><Del reference={this} /><Copy reference={this} /></React.Fragment>), this.tdraw());
    }

    drawMenu(row, rowindex) {
        return (<Menu key={Date.now()} reference={this} row={row} rowindex={rowindex} />);
    }

    drawCell(row, rowindex, cellindex) {
        if (cellindex === 1) return row.ID;
        if (cellindex === 2) return window.profiletype[row.type] ? window.profiletype[row.type] : '';
        if (cellindex === 3) return row.name;
    }

    drawEmptyCell() {
        return window.lan.profile.empty;
    }

    drawToolbarLeft() {
        return (<Button variant="contained" disableElevation onClick={() => { if (this.state.selected.length === 0) return; this.del_diag.value = { "IDList": this.state.selected.join(',') }; this.del_diag.openmain(); }}>{window.lan.profile.del}</Button>);
    }

    drawToolbarRight() {
        return (<Button variant="outlined" disableElevation onClick={() => { this.add_diag.openmain(); }}>{window.lan.profile.add}</Button>);
    }
}



if (!window.logfun) window.logfun = {};

window.logfun.add_profile = function (info) {
    return window.lan.loginfo.add_profile.replace('%A%', info.id).replace('%B%', info.title);
}

window.logfun.remove_profiles = function (info) {
    return window.lan.loginfo.remove_profiles.replace('%A%', info.ids.join(', '));
}

window.logfun.edit_profile = function (info) {
    return window.lan.loginfo.edit_profile.replace('%A%', info.id).replace('%B%', info.title);
}

window.logfun.copy_profile = function (info) {
    return window.lan.loginfo.copy_profile.replace('%A%', info.profile_id).replace('%B%', info.id);
}



export default Profile;

import * as React from 'react';

import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { initialize_title, modify_title } from '../../main/App';
import AbstractTable from '../../main/AbstractTable';
import { TooltipWrapper, TextInput, SelectInput, AbstractDialog } from '../../main/AbstractForm';
import { AbstractMenu } from '../../main/AbstractMenu';



class Add extends AbstractDialog {
    constructor(props) {
        super('/feature/draft.basic', 'post', { 'name': '' }, props.reference, 'add_diag', window.lan.draft.add, window.lan.draft.add_submit);
    }

    options() {
        return [
            (<TextInput form={this} id="name" label={window.lan.draft.add_name} fullwidth />)
        ];
    }

    validate(id, value) {
        if (id === 'name') return value.length < 1 || value.length > 31 ? window.lan.draft.err[0] : '';
        return '';
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.add(info.id);
        this.reference.refresh();
    }
}



class Edit extends AbstractDialog {
    constructor(props) {
        super('/feature/draft.basic', 'put', {}, props.reference, 'edit_diag', window.lan.draft.edit, window.lan.draft.edit_submit, 60);
    }

    options() {
        const options = [];
        Object.entries(window.drafttype).forEach(([key, name], i) => {
            options.push({ name: name, value: key });
        });

        return [
            (<TextInput form={this} id="name" label={window.lan.draft.edit_name} fullwidth />),
            (<SelectInput form={this} id="type" label={window.lan.draft.edit_type} options={options} fullwidth />),
            (<TooltipWrapper input={<TextInput form={this} id="title" label={window.lan.draft.edit_title} fullwidth />} tooltip={window.lan.draft.edit_title_tip} />),
            (<TooltipWrapper input={<TextInput form={this} id="body" label={window.lan.draft.edit_body} fullwidth multiline rows={8} />} tooltip={window.lan.draft.edit_body_tip} />)
        ];
    }

    validate(id, value) {
        if (id === 'name') return value.length < 1 || value.length > 31 ? window.lan.draft.err[0] : '';
        if (id === 'title') return value.length > 255 ? window.lan.draft.err[1] : '';
        if (id === 'body') return value.length > 65535 ? window.lan.draft.err[2] : '';
        return '';
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.update(info.id);
        this.reference.refresh();
    }
}



class Del extends AbstractDialog {
    constructor(props) {
        super('/feature/draft.basic', 'delete', {}, props.reference, 'del_diag', window.lan.draft.del, window.lan.general.submit);
    }

    options() {
        return [window.lan.draft.del_tip];
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.remove(info.list);
        this.reference.refresh();
    }
}



class Copy extends AbstractDialog {
    constructor(props) {
        super('/feature/draft.basic/copy', 'post', {}, props.reference, 'copy_diag');
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.add(info.id);
        this.reference.refresh();
    }
}



class Menu extends AbstractMenu {
    constructor(props) {
        const items = [];
        items.push({ name: window.lan.draft.copy, icon: (<ContentCopyIcon fontSize="small" />), fun: () => { props.reference.copy_diag.value = { 'draftID': props.row.ID }; props.reference.copy_diag.submit(); } });
        items.push({ name: window.lan.draft.edit, icon: (<EditIcon fontSize="small" />), fun: () => { props.reference.edit_diag.value = { ...props.row }; props.reference.edit_diag.openmain(); } });
        items.push({ name: window.lan.draft.del, icon: (<ClearIcon fontSize="small" />), fun: () => { props.reference.del_diag.value = { "IDList": props.row.ID }; props.reference.del_diag.openmain(); } });
        super([{ title: window.lan.general.operation, items: items }]);
    }
}



class Draft extends AbstractTable {

    constructor() {
        super('/feature/draft.basic',
            [
                { sortindex: 0, label: window.lan.draft.id },
                { sortindex: 2, label: window.lan.draft.type },
                { sortindex: 1, label: window.lan.draft.name, style: { width: '70%' } }
            ],
            window.lan.draft.infobox, 'Draft', '', '0$0', [],
            [
                window.lan.draft.id,
                window.lan.draft.name,
                window.lan.draft.type,
                window.lan.draft.tit
            ], true);
    }

    draw() {
        initialize_title();
        modify_title(2, window.lan.draft.title);
        return this.pdraw([{ info: window.lan.draft.title }], (<React.Fragment><Add reference={this} /><Edit reference={this} /><Del reference={this} /><Copy reference={this} /></React.Fragment>), this.tdraw());
    }

    drawMenu(row, rowindex) {
        return (<Menu key={Date.now()} reference={this} row={row} rowindex={rowindex} />);
    }

    drawCell(row, rowindex, cellindex) {
        if (cellindex === 1) return row.ID;
        if (cellindex === 2) return window.drafttype[row.type] ? window.drafttype[row.type] : '';
        if (cellindex === 3) return row.name;
    }

    drawEmptyCell() {
        return window.lan.draft.empty;
    }

    drawToolbarLeft() {
        return (<Button variant="contained" disableElevation onClick={() => { if (this.state.selected.length === 0) return; this.del_diag.value = { "IDList": this.state.selected.join(',') }; this.del_diag.openmain(); }}>{window.lan.draft.del}</Button>);
    }

    drawToolbarRight() {
        return (<Button variant="outlined" disableElevation onClick={() => { this.add_diag.openmain(); }}>{window.lan.draft.add}</Button>);
    }
}



if (!window.logfun) window.logfun = {};

window.logfun.add_draft = function (info) {
    return window.lan.loginfo.add_draft.replace('%A%', info.id).replace('%B%', info.title);
}

window.logfun.remove_drafts = function (info) {
    return window.lan.loginfo.remove_drafts.replace('%A%', info.ids.join(', '));
}

window.logfun.edit_draft = function (info) {
    return window.lan.loginfo.edit_draft.replace('%A%', info.id).replace('%B%', info.title);
}

window.logfun.copy_draft = function (info) {
    return window.lan.loginfo.copy_draft.replace('%A%', info.draft_id).replace('%B%', info.id);
}



export default Draft;
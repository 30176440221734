import * as React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import PasswordIcon from '@mui/icons-material/Password';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import { initialize_title, load, modify_title } from '../../main/App';
import AbstractTable from '../../main/AbstractTable';
import { TooltipWrapper, GroupWrapper, TextInput, SelectInput, CheckboxInput, AbstractDialog } from '../../main/AbstractForm';
import { AbstractMenu } from '../../main/AbstractMenu';
import { checkEmail, getTimeStr, getIPStr } from '../../main/Utility';



class Add extends AbstractDialog {
    constructor(props) {
        super('/feature/administrator.basic', 'post', { 'name': '' }, props.reference, 'add_diag', window.lan.administrator.add, window.lan.administrator.add_submit);
    }

    options() {
        return [
            <TextInput form={this} id="name" label={window.lan.administrator.add_name} fullwidth />
        ];
    }

    validate(id, value) {
        if (id === 'name') return value.length < 1 || value.length > 31 ? window.lan.administrator.err[0] : '';
        return '';
    }

    result(result, info) {
        if (result === 1 || result === 9) {
            this.validity['name'] = window.lan.administrator.err[0];
            this.openmain();
            return;
        }
        if (result === 0)
            this.reference.dm.add(info.id);
        this.reference.refresh();
    }
}



class Chgpass extends AbstractDialog {
    constructor(props) {
        super('/feature/administrator.basic/password', 'put', { 'pass': '', 'passc': '' }, props.reference, 'chgpass_diag', window.lan.administrator.chgpass, window.lan.administrator.chgpass_submit);
    }

    options() {
        return [
            (<Stack direction="row" spacing={5}>
                <TextInput form={this} id="pass" label={window.lan.administrator.chgpass_pass} type="password" fullwidth />
                <TextInput form={this} id="passc" label={window.lan.administrator.chgpass_passc} type="password" fullwidth />
            </Stack>)
        ];
    }

    validate(id, value) {
        if (id === 'pass') return value.length < 8 || value.length > 32 ? window.lan.administrator.err[3] : '';
        if (id === 'passc') return value !== this.value['pass'] ? window.lan.administrator.err[4] : '';
        return '';
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.update(info.id);
        this.reference.refresh();
    }
}



class Edit extends AbstractDialog {
    constructor(props) {
        super('/feature/administrator.basic', 'put', {}, props.reference, 'edit_diag', window.lan.administrator.edit, window.lan.administrator.edit_submit);
    }

    getRightSelection(key, info) {
        const options = info.level.map((name, i) => ({ name: name, value: i}));
        return (<TooltipWrapper key={key} input={<SelectInput form={this} id={'_right_' + key} label={info.name} options={options} fullwidth />} tooltip={info.tip} />);
    }

    openmain() {
        const rightObj = JSON.parse(this.value.right);
        Object.keys(window.lan.right).forEach((key) => {
            this.value['_right_' + key] = rightObj[key] ? rightObj[key] : 0;
        });

        window.config.buffer['group/group'].forEach((item) => {
            this.value['_group_' + item.index] = (this.value['group'] & (1 << item.index)) ? '1' : '';
        });

        super.openmain();
    }

    options() {
        const options = [
            (<GroupWrapper title={window.lan.administrator.edit_group} input={<Grid container direction="row" spacing={0}>{
                window.config.buffer['group/group'].map((item) => <Grid item key={"item_" + item.index}><CheckboxInput form={this} id={"_group_" + item.index} disabled={item.index < 8} label={item.index < 8 ? window.groupname[item.index] : item.name} value="1" /></Grid>)
            }</Grid>} />),
            (<TooltipWrapper input={<TextInput form={this} id="email" label={window.lan.administrator.edit_email} fullwidth />} tooltip={window.lan.administrator.edit_email_tip} />),
            (<TextInput form={this} id="remark" label={window.lan.administrator.edit_remark} fullwidth multiline rows={4} />)
        ];
        let buffer = [];
        Object.entries(window.lan.right).forEach(([key, info]) => {
            if (buffer.length === 3)
            {
                options.push(<Stack direction="row" spacing={5}>{buffer}</Stack>);
                buffer = [];
            }
            buffer.push(this.getRightSelection(key, info));
        });
        if (buffer.length > 0)
        {
            while (buffer.length < 3) buffer.push(<Box sx={{ width: '100%' }}></Box>);
            options.push(<Stack direction="row" spacing={5}>{buffer}</Stack>);
        }
        return options;
    }

    presubmit() {
        if (!super.presubmit()) return false;

        const right = {};
        Object.keys(window.lan.right).forEach((key) => {
            if (this.value['_right_' + key] > 0) right[key] = this.value['_right_' + key];
        });
        this.value.right = JSON.stringify(right);
        
        let group = 0;
        window.config.buffer['group/group'].forEach((item) => {
            if (this.value['_group_' + item.index] === '1') group |= (1 << item.index);
        });
        this.value['group'] = group;

        for (const key in this.value) if (key.charAt(0) === '_') delete this.value[key];
        return true;
    }

    validate(id, value) {
        if (id === 'email') return value.length > 0 && !checkEmail(value) ? window.lan.administrator.err[1] : '';
        if (id === 'remark') return value.length > 4095 ? window.lan.administrator.err[2] : '';
        return '';
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.update(info.id);
        this.reference.refresh();
    }
}



class Del extends AbstractDialog {
    constructor(props) {
        super('/feature/administrator.basic', 'delete', {}, props.reference, 'del_diag', window.lan.administrator.del, window.lan.general.submit);
    }

    options() {
        return [window.lan.administrator.del_tip];
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.remove(info.list);
        this.reference.refresh();
    }
}



class Menu extends AbstractMenu {

    constructor(props) {
        const items = [];
        if (window.config.admin.right[0] & 10) items.push({ name: window.lan.administrator.log_tip, icon: (<FormatListBulletedIcon fontSize="small" />), fun: () => { load('/log/2$1|3$' + props.row.ID + '$0'); } });
        if (props.row.ID !== window.config.admin.id) {
            items.push({});
            items.push({ name: window.lan.administrator.edit, icon: (<EditIcon fontSize="small" />), fun: () => { props.reference.edit_diag.value = {...props.row}; props.reference.edit_diag.openmain(); } });
            items.push({ name: window.lan.administrator.chgpass, icon: (<PasswordIcon fontSize="small" />), fun: () => { props.reference.chgpass_diag.value = { "ID": props.row.ID }; props.reference.chgpass_diag.openmain(); } });
            items.push({ name: window.lan.administrator.del, icon: (<ClearIcon fontSize="small" />), fun: () => { props.reference.del_diag.value = { "IDList": props.row.ID }; props.reference.del_diag.openmain(); } });
        }
        super([{ title: window.lan.general.operation, items: items }]);
    }
}



class Administrator extends AbstractTable {

    constructor() {
        super('/feature/administrator.basic',
            [
                { sortindex: 0, label: window.lan.administrator.id },
                { sortindex: 1, label: window.lan.administrator.name },
                { sortindex: 2, label: window.lan.administrator.email },
                { sortindex: -1, label: window.lan.administrator.group },
                { sortindex: 3, label: window.lan.administrator.access_time },
                { sortindex: 4, label: window.lan.administrator.access_ip }
            ],
            window.lan.administrator.infobox, 'Administrator', '', '0$0', [],
            [
                window.lan.administrator.id,
                window.lan.administrator.name,
                window.lan.administrator.email,
                window.lan.administrator.access_time,
                window.lan.administrator.access_ip
            ], true, true);
    }

    draw() {
        initialize_title();
        modify_title(2, window.lan.administrator.title);
        return this.pdraw([{ info: window.lan.administrator.title }], (<React.Fragment><Add reference={this} /><Chgpass reference={this} /><Edit reference={this} /><Del reference={this} /></React.Fragment>), this.tdraw());
    }

    drawMenu(row, rowindex) {
        return (<Menu key={Date.now()} reference={this} row={row} rowindex={rowindex} />);
    }

    drawDetail(row, rowindex) {
        return (<Grid container justifyContent="flex-start" sx={{ padding: '5px' }}>
            <Grid item xs={12}><Typography align="left"><b>{window.lan.administrator.remark}:</b><br />{row.remark !== '' ? row.remark : window.lan.general.none}</Typography></Grid>
        </Grid>);
    }

    drawCell(row, rowindex, cellindex) {
        if (cellindex === 1) return row.ID;
        if (cellindex === 2) {
            return (<Stack direction="row" spacing={2}><Typography>{row.name}</Typography>
                {row.ID === window.config.admin.id ? <Chip label={window.lan.administrator.current} color="primary" size="small" /> : null}
                {Object.keys(JSON.parse(row.right)).length > 0 ? <Chip label={window.lan.administrator.right_tip} color="warning" size="small" /> : null}
                {row.accessTime > 0 && Date.now() / 1000 - row.accessTime > 3 * 86400 ? <Chip
                    label={(Date.now() / 1000 - row.accessTime > 30 * 86400 ? window.lan.administrator.login_long_tip : window.lan.administrator.login_tip).replace('%A%', Math.round((Date.now() / 1000 - row.accessTime) / 86400))}
                    color="secondary" size="small" /> : null}</Stack>);
        }
        if (cellindex === 3) return row.email;
        if (cellindex === 4) {
            const groupArr = [];
            window.config.buffer['group/group'].forEach((item) => { if (row.group & (1 << item.index)) groupArr.push(item.index < 8 ? window.groupname[row.index] : item.name); });
            return groupArr.length > 0 ? groupArr.join(', ') : '';
        }
        if (cellindex === 5) return row.accessTime > 0 ? getTimeStr(row.accessTime) : '';
        if (cellindex === 6) return row.accessIP !== 0 ? getIPStr(row.accessIP) : '';
    }

    drawEmptyCell() {
        return window.lan.administrator.empty;
    }

    drawToolbarLeft() {
        return (<Button variant="contained" disableElevation onClick={() => { if (this.state.selected.includes(window.config.admin.id)) { this.del_diag.openresult(window.lan.administrator.err[5]); return; } if (this.state.selected.length === 0) return; this.del_diag.value = { "IDList": this.state.selected.join(',') }; this.del_diag.openmain(); }}>{window.lan.administrator.del}</Button>);
    }

    drawToolbarRight() {
        return (<Button variant="outlined" disableElevation onClick={() => { this.add_diag.openmain(); }}>{window.lan.administrator.add}</Button>);
    }
}



if (!window.logfun) window.logfun = {};

window.logfun.add_administrator = function (info) {
    return window.lan.loginfo.add_administrator.replace('%A%', info.id).replace('%B%', info.name);
}

window.logfun.remove_administrators = function (info) {
    return window.lan.loginfo.remove_administrators.replace('%A%', info.ids.join(', '));
}

window.logfun.edit_administrator = function (info) {
    return window.lan.loginfo.edit_administrator.replace('%A%', info.id).replace('%B%', info.right);
}

window.logfun.password_administrator = function (info) {
    return window.lan.loginfo.password_administrator.replace('%A%', info.id);
}



export default Administrator;